import React from "react";
import "./footer.css";
const footer = () => {
  return (
    <div className="footer-container">
      <p>
        Center Address :- CTET MASTERMINDS, Ashok Nagar, Road No.-13( Near Bank
        of Baroda ), Kankarbagh, Patna
      </p>
    </div>
  );
};
export default footer;
