import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Home = lazy(() => import("../Containers/Home/index"));
const AboutUs = lazy(() => import("../Containers/AboutUs/index"));
const ContactUs = lazy(() => import("../Containers/ContactUs/index"));
const Gallery = lazy(() => import("../Containers/Gallery/index"));
const Courses = lazy(() => import("../Containers/Courses/index"));

const route = (
  <React.Fragment>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/courses" element={<Courses />} />
    </Routes>
  </React.Fragment>
);
export default route;
