import React, { Suspense } from "react";
import "./App.css";
import routes from "./Routes/index";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./Containers/Header/index";
import Footer from "./Containers/Footer/index";
const App = () => {
  return (
    <div className="App">
      <Suspense fallback={<div>Loading</div>}>
        <Container fluid>
          <Row>
            <Col>
              <Header />
            </Col>
          </Row>
          <Row>
            <Col>
            {routes}
            </Col>
          </Row>
          <Row>
            <Col>
              <Footer />
            </Col>
          </Row>
        </Container>
      </Suspense>
    </div>
  );
};

export default App;
